import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c916479e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mfd-square" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", {
      class: _normalizeClass(["mfd-square-content", { width: _ctx.width }])
    }, _toDisplayString(_ctx.content), 3)
  ]))
}