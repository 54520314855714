
import { Options, Vue } from 'vue-class-component'

@Options({
  props: {
    content: String,
    value: Number,
    width: Number,
    height: Number
  }
})
export default class MFDSquare extends Vue {
  public content!: string;
  public value!: number;
  public width!: number;
  public height!: number;
  public left!: number;
  public top!: number;
}
