
import { TsWebSocket2 } from '@/data/websocket2';
import { Options, Vue } from 'vue-class-component'
/**
 * https://jsfiddle.net/4atx3nb8/
 * SPEEDO START
 * https://jsbin.com/kopisonewi/2/edit?html,js,output
 * ARC DRAWING METHOD
 */

@Options({
  props: {
    tapeDistance: Number
  },
})
export default class ETCSDistanceTape extends Vue {
  public tapeDistance!: number;

  public getTapePercentage() {
    const maxDist = 3000;
    const halfWayDist = 1000;

    const dist = this.tapeDistance;
    const diff = maxDist - dist;
    if (dist > halfWayDist) {
      const halwayDiff = maxDist - halfWayDist
      const step = halwayDiff / 50;
      return (diff / step) + "%";
    } else {
      const step = halfWayDist * 2 / 100

      return 100 - (dist / step) + "%";
    }
  }
}
