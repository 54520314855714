
import { TsWebSocket2 } from '@/data/websocket2';
import { Options, Vue } from 'vue-class-component'
import MFDSquare from './MFDSquare.vue'

@Options({
    components: {
        MFDSquare
    },
    computed: {
        atbSpeed:            { get() { return Math.round(TsWebSocket2.Instance.values.ATBSpeedIndicator ?? 0); }},
    },
})
export default class ATBBar extends Vue {
  public atbSpeed!: number

  public mounted(): void {
      TsWebSocket2.Instance.subscribe(["ATBSpeedIndicator"]);
  }
}
