
import { TsWebSocket2 } from '@/data/websocket2';
import { ETCSBarData, ETCSFlagData } from '@/types';
import { Options, Vue } from 'vue-class-component'
/**
 * https://jsfiddle.net/4atx3nb8/
 * SPEEDO START
 * https://jsbin.com/kopisonewi/2/edit?html,js,output
 * ARC DRAWING METHOD
 */

@Options({
  computed: {
    etcsBrakeBar: { get() { return TsWebSocket2.Instance.values["pasp_brakebar"] ?? 0 }},
    etcsBarData:  { get() {
        const vals = TsWebSocket2.Instance.values;
        return [
            { speed: vals["paspbar_1_speed"], dist: vals["paspbar_1_dist"] },
            { speed: vals["paspbar_2_speed"], dist: vals["paspbar_2_dist"] },
            { speed: vals["paspbar_3_speed"], dist: vals["paspbar_3_dist"] },
            { speed: vals["paspbar_4_speed"], dist: vals["paspbar_4_dist"] },
        ]
    }},
    etcsFlagData: {
        get() {
            return {
                stop: TsWebSocket2.Instance.values.pasp_downArrStop,
                down1: TsWebSocket2.Instance.values.pasp_downArr1,
                down2: TsWebSocket2.Instance.values.pasp_downArr2,
                down3: TsWebSocket2.Instance.values.pasp_downArr3,
                down4: TsWebSocket2.Instance.values.pasp_downArr4,
                up1: TsWebSocket2.Instance.values.pasp_upArr1,
                up2: TsWebSocket2.Instance.values.pasp_upArr2,
                up3: TsWebSocket2.Instance.values.pasp_upArr3,
                up4: TsWebSocket2.Instance.values.pasp_upArr4,
            }
        },
    }
  }
})
export default class ETCSDisplay extends Vue {
    public etcsBrakeBar!: number;
    public etcsBarData!: Array<ETCSBarData>;
    public etcsFlagData!: ETCSFlagData;

    public mounted(): void {
        TsWebSocket2.Instance.subscribe(["pasp_brakebar", "paspbar_1_speed", "paspbar_2_speed", "paspbar_3_speed", "paspbar_4_speed", "paspbar_1_dist", "paspbar_2_dist", "paspbar_3_dist", "paspbar_4_dist", "pasp_downArrStop", "pasp_downArr1", "pasp_downArr2", "pasp_downArr3", "pasp_downArr4", "pasp_upArr1", "pasp_upArr2", "pasp_upArr3", "pasp_upArr4"])
    }

    public get brakeBarPos() {
        return 100 - (this.etcsBrakeBar * 100) + "%";
    }

    public getFlagPos(name: string): number {
        return this.etcsFlagData?.[name] ?? 0;
    }

    public getBarXpos(i: number): string {
        const speed = this.etcsBarData[i]?.speed ?? 0;
        return speed === 0.25 ? "90%" : speed === 0.5 ? '80%' : speed === 0.75 ? '70%' : '60%';
    }

    public getBarWidth(i: number) {
        const speed = this.etcsBarData[i]?.speed ?? 0;
        return speed === 0.25 ? "10%" : speed === 0.5 ? '20%' : speed === 0.75 ? '30%' : '40%';

    }

    public getBarHeight(i: number) {
        const dist = this.etcsBarData[i]?.dist ?? 0;
        return dist * 100 + "%";
    }
}
