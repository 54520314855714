import { Vue } from "vue-class-component";
import { IBaseComp, IControllerValue } from "@/interfaces/interfaces";
import { reactive, ref } from "vue";

export interface IControllerValues {
    data: Array<IControllerValue>
}

export interface ISimInfo {
    connected: boolean;
    locoName: string;
}

export interface ITSWebsocketData {
    type: WebSocketTransferTypes,
    data?: { [name: string]: number },
    simInfo?: ISimInfo,
}

export enum WebSocketTransferTypes {
    StartTransfer,
    StopTransfer,
    SetControlValue,
    UpdateControls,
    ControlData,
    TrainChange
}

export class TsWebSocket2 {
    public static get Instance(): TsWebSocket2 {
        if (!this._instance) {
            this._instance = new TsWebSocket2();
        }

        return this._instance;
    }

    private static _instance: TsWebSocket2;

    protected socket: WebSocket | undefined;
    protected controls: string[] = [];

    protected subscribers = new Map<Vue & IBaseComp, string[]>();

    private _ip: string = "";

    public get ip(): string {
        return this._ip;
    }

    private preventReconnect = false;


    public isConnected = ref(false);

    public onopen?: () => void;
    public onupdate?: (data: Array<{ key: string, value: number }>) => void;

    public values: {[ndx: string]: number } = reactive({});
    public simInfo: ISimInfo = reactive({ connected: false, locoName: "" });

    public updateValues(values: IControllerValue[]) {
      for (const value of values) {
        this.values[value.key] = value.value ?? 0;
      }
    }
    
    public subscribe(controls: string | string[]): void {
        if (typeof controls === "string") {
            controls = [controls];
        }
        const newControls = controls.filter(control => this.controls.indexOf(control) === -1);
        if (newControls.length) {
            this.controls.push(...newControls);
            this.updateControls();
        }

    }

    protected update(json: ITSWebsocketData): void {
        const { data, simInfo } = json;
        if (data) {
            for (const key of Object.keys(data)) {
                this.values[key] = data[key];
            }
        }
        if (simInfo) {
            if (!this.simInfo) {
                this.simInfo = simInfo;
            }
            this.simInfo.connected = simInfo.connected;
            this.simInfo.locoName = simInfo.locoName;
        }
    }

    public send(json: ITSWebsocketData): void {
        if (this.socket?.readyState === 0) {
            return;
        }
        this.socket?.send(JSON.stringify(json));
    }

    public connect(ip: string, reconnect?: boolean): void {
        if (this._ip !== ip || reconnect) {
            this.stopTransfer();
            this.disconnect();
            this._ip = ip;
        }

        this.socket ??= new WebSocket(this._ip);

        this.socket.onmessage = (ev: MessageEvent) => {
            const data = ev.data;
            if (!data) {
                console.error("No data");
                return;
            }

            const json = JSON.parse(data) as ITSWebsocketData;
            if (json.type === WebSocketTransferTypes.ControlData) {
                this.update(json);
            }
        }

        this.socket.onerror = () => {
            console.error("WS Error, connection failed");
            this.attemptReConnect();
        }

        this.socket.onopen = () => {
            console.info("Socket opened on " + ip);
            this.onopen?.();
            this.isConnected.value = true;
            setTimeout(() => this.startTransfer(), 100);
        }

        this.socket.onclose = () => {
            if (this.isConnected.value) {
                console.info("socket closed on " + ip);
            }
            this.isConnected.value = false;
            this.attemptReConnect();
        }
    }

    protected attemptReConnect(): void {
        if (this.preventReconnect) {
            this.preventReconnect = false;
            return;
        }
        if (this.isConnected.value) {
            return
        }

        this.socket?.close();
        this.socket = undefined;
        console.info("attempting reconnect on " + this._ip);
        setTimeout(() => {
            this.connect(this._ip, true);
        }, 500)
    }

    public updateControls(): void {
        if (!this.socket) {
            return;
        }

        const ctrlData = this.controls.reduce((allCtrls, ctrl) => ({...allCtrls, [ctrl]: 0 }), {});
        const json = {
            type: WebSocketTransferTypes.UpdateControls,
            data: ctrlData
        };
        this.send(json);
    }

    public startTransfer(): boolean {
        if (!this.socket) {
            return false;
        }

        this.updateControls();

        this.send({
            type: WebSocketTransferTypes.StartTransfer,
        });

        return true;
    }

    public stopTransfer(): boolean {
        if (!this.socket) {
            return false;
        }

        this.send({
            type: WebSocketTransferTypes.StopTransfer,
        });

        return false;
    }

    public disconnect(): void {
        this.preventReconnect = true;
        this.socket?.close();
        this.socket = undefined;
    }
}
