import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4279a959"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ATB-bar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MFDSquare = _resolveComponent("MFDSquare")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_MFDSquare, {
      content: "14",
      class: _normalizeClass(["ATB ATB-Groen", { on: _ctx.atbSpeed === 140 }])
    }, null, 8, ["class"]),
    _createVNode(_component_MFDSquare, {
      content: "13",
      class: _normalizeClass(["ATB ATB-Geel", { on: _ctx.atbSpeed === 130 }])
    }, null, 8, ["class"]),
    _createVNode(_component_MFDSquare, {
      content: "8",
      class: _normalizeClass(["ATB ATB-Geel", { on: _ctx.atbSpeed === 80 }])
    }, null, 8, ["class"]),
    _createVNode(_component_MFDSquare, {
      content: "6",
      class: _normalizeClass(["ATB ATB-Geel", { on: _ctx.atbSpeed === 60 }])
    }, null, 8, ["class"]),
    _createVNode(_component_MFDSquare, {
      content: "",
      class: _normalizeClass(["ATB ATB-Geel", { on: _ctx.atbSpeed === 40 }])
    }, null, 8, ["class"])
  ]))
}