
import { TsWebSocket2 } from '@/data/websocket2';
import { Options, Vue } from 'vue-class-component'
/**
 * https://jsfiddle.net/4atx3nb8/
 * SPEEDO START
 * https://jsbin.com/kopisonewi/2/edit?html,js,output
 * ARC DRAWING METHOD
 */

@Options({
  props: {
    safetySystem: String,
    lastPacManY: Number,
  },
  computed: {
    speed:            { get() { return Math.round(TsWebSocket2.Instance.values.SpeedometerKPH ?? 0); }},
    etcsTargetSpeed:  { get() { return TsWebSocket2.Instance.values.ETCSTargetSpeedRing ?? 0; }},
    etcsPacManY:      { get() { return TsWebSocket2.Instance.values.ETCSPacManY ?? 0; }},
    etcsPacManW:      { get() { return TsWebSocket2.Instance.values.ETCSPacManW ?? 0; }},
    etcsServiceBrake: { get() { return TsWebSocket2.Instance.values.ETCSServiceBrakeLight ?? 0; }},
    redLightPassed:   { get() { return TsWebSocket2.Instance.values.ETCSRedLightPassedLight ?? 0; }},
    etcsMode:         { get() { return TsWebSocket2.Instance.values.ETCSModeLight ?? 0; }},
  }
})
export default class MFDSpeedo extends Vue {
  public svgItem?: HTMLElement;

  public speed!: number;
  public etcsTargetSpeed!: number;
  public etcsPacManY!: number;
  public etcsPacManW!: number;
  public etcsServiceBrake!: number;
  public safetySystem!: string;
  public maxSpeed = 250;
  public etcsMode!: number;

  public redLightPassed!: number;

  private lastPacManY: number = 0;

  public get isPacManYMoving() {
    
    const lastPos = this.lastPacManY || this.etcsPacManY;
    const pos = this.etcsPacManY;
    const isDiff = pos > 0 && lastPos !== pos;
    this.lastPacManY = pos;
    return isDiff;
  }

  public speedStep = 5;
  public speedStepText = 20;

  public dataInstance!: TsWebSocket2;

  public mounted(): void {
    this.dataInstance = TsWebSocket2.Instance;
    this.dataInstance.subscribe(["SpeedometerKPH", "ETCSTargetSpeedRing", "ETCSPacManY", "ETCSPacManW", "ETCSServiceBrakeLight", "ETCSModeLight"]);
  }

  public get ETCSArc() {
    return Math.round(this.etcsPacManW || this.etcsPacManY);
  }

  public get speedStepTextCounter() {
    return Math.floor(this.maxSpeed / this.speedStepText);
  }

  public get speedoAngle() {
    return this.speedToAngle(this.speed) - 270;
  }

  public get pacManStartAngle() {
    if (this.speed < this.etcsTargetSpeed) {
      return this.speedToAngle(this.etcsTargetSpeed);
    }
    return 40;
  }

  public get maxSpeedColor() {
    if (this.etcsServiceBrake) {
      return "red";
    }
    if (this.isSpeeding) {
      return "#F26521";
    }
    if (this.etcsPacManY) {
      return this.isPacManYMoving && this.etcsPacManY - this.speed < 15 ? "yellow" : "white";
    } else if (this.etcsPacManW) {
      return "white";
    }
    return "gray";
  }

  public get maxSpeedColorWhite() {
    return this.maxSpeedColor === "gray" ? "white" : this.maxSpeedColor;
  }

  public get highestAllowedETCSSpeed() {
    const pacManSpeed = this.etcsPacManY || this.etcsPacManW;
    return Math.round(pacManSpeed > this.etcsTargetSpeed ? pacManSpeed : this.etcsTargetSpeed);
  }

  public get isSpeeding() {
    if (this.safetySystem !== "ETCS" || this.etcsMode === 1) {
      return false;
    }
    if (this.etcsMode === 3 || this.etcsMode === 0) {
      return this.speed > 40;
    }
    return Math.round(this.speed) > this.highestAllowedETCSSpeed;
  }

  public speedToAngle(speed: number): number {
    // console.warn(`speed ${speed}km/h`, this.etcsTargetSpeed, this.etcsPacManW, this.etcsPacManY);
    if (!speed || speed < 1) return 40;
    const totalAngle = 280;
    const speedStep = totalAngle / this.maxSpeed;
    return 40 + speed * speedStep;
  }

  public polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

    return {
      x: centerX + (radius * Math.cos(angleInRadians)),
      y: centerY + (radius * Math.sin(angleInRadians))
    };
  }

  public describeArc(x: number, y: number, radius: number, startAngle: number, endAngle: number) {
    const start = this.polarToCartesian(x, y, radius, endAngle);
    const end = this.polarToCartesian(x, y, radius, startAngle);

    let largeArcFlag = "0";
    if (endAngle >= startAngle) {
      largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";
    } else {
      largeArcFlag = (endAngle + 360.0) - startAngle <= 180 ? "0" : "1";
    }

    const d = [
        "M", start.x, start.y,
        "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(" ");

    return d;
  }
}
