
import { Options, Vue } from 'vue-class-component'
import ATBBar from './components/ATBBar.vue'
import MFDSpeedo from './components/MFDSpeedo.vue'
import ETCSDisplay from './components/ETCSDisplay.vue'
import { ETCSBarData, ETCSFlagData } from './types'
import MFDMessages from './components/MFDMessages.vue'
import MFDSquare from './components/MFDSquare.vue'
import MFDSettingsScreen from './components/MFDSettingsScreen.vue'
import ETCSDistanceTape from './components/ETCSDistanceTape.vue'
import { TsWebSocket2, WebSocketTransferTypes, ISimInfo } from './data/websocket2'

@Options({
  components: {
    ATBBar,
    MFDSpeedo,
    MFDSquare,
    ETCSDisplay,
    MFDMessages,
    MFDSettingsScreen,
    ETCSDistanceTape
  },
  props: {
    dataRunning: Boolean,
  },
  computed: {
    atbSpeed:         { get() { return TsWebSocket2.Instance.values.ATBSpeedIndicator ?? 0 }},
    safetySystemType: { get() { return TsWebSocket2.Instance.values.SafetySystemType ?? 0 }},
    deadManLight:     { get() { return TsWebSocket2.Instance.values.DeadManWarningLight ?? 0 }},
    deadManActive:    { get() { return TsWebSocket2.Instance.values.DeadManSystem ?? 0 }},
    etcsDistanceTape: { get() { return TsWebSocket2.Instance.values.ETCSDistanceTape ?? 0 }},
    safetySystemSwap: { get() { return TsWebSocket2.Instance.values.SafetySystemSwapButton ?? 0 }},
    ebrakeMessage:    { get() { return TsWebSocket2.Instance.values.ERTMSebrakeMessage ?? 0 }},
    redLightPassed:   { get() { return TsWebSocket2.Instance.values.ETCSRedLightPassedLight ?? false }},
    etcsShuntSRlight: { get() { return TsWebSocket2.Instance.values.ETCSshuntSRlight ?? false }},
    etcsModeLight:    { get() { return TsWebSocket2.Instance.values.ETCSModeLight ?? false }},
    etcsMenuBar:      { get() { return TsWebSocket2.Instance.values.ETCSMenuBar ?? 0 }},
    pantoCondition:   { get() { return TsWebSocket2.Instance.values.ETCSPantoCondition ?? false }},
    pantoVoltage:     { get() { return TsWebSocket2.Instance.values.ETCSPantoVoltage ?? false }},
    simConnected:     { get() { return TsWebSocket2.Instance.simInfo.connected ?? false }},
    locoName:         { get() { return TsWebSocket2.Instance.simInfo.locoName ?? "" }},
    wsConnected:      { get() { return TsWebSocket2.Instance.isConnected.value ?? false }},
    safetySystemAcceptButton: { get() { return TsWebSocket2.Instance.values.SafetySystemAcceptButton}},
    deadManSound: { get() { return TsWebSocket2.Instance.values.DeadMansAlarm}},
  }
})
export default class App extends Vue {
  public tsData = new Map<string, number>();
  public atbSpeed!: number;
  public deadManLight!: number;
  public deadManSound!: number;
  public deadManActive!: number;
  public dataRunning = false;

  public settingsOpen = false;

  public etcsModeLight!: number;
  public etcsMenuBar!: number;
  public etcsBrakeBar = 0;
  public safetySystemAcceptButton!: number;

  public redLightPassed!: number;
  public safetySystemType!: number;
  public etcsDataChanged = 0;
  public dataConnector2!: TsWebSocket2;
  public ebrakeMessage!: number;
  public safetySystemSwap!: number;
  public etcsDistanceTape!: number;
  public etcsShuntSRlight!: number;
  public pantoCondition!: number;
  public pantoVoltage!: number;

  public ip = "";

  public etcsBarData: Array<ETCSBarData> = [];
  public etcsFlagData: ETCSFlagData = {};

  public simConnected!: boolean;
  public simInfo!: ISimInfo;
  public locoName!: string;

  public showShuntOptions: boolean = false;
  public showOverrideOptions: boolean = false;
  
  public wsConnected!: boolean;

  private useWakeLock: boolean = false;

  public get fullIP() {
    return "ws://" + this.ip + ":4201/TsClient";
  }

  public wakelock: AbortController | null = null

  public mounted(): void {
      this.dataConnector2 = TsWebSocket2.Instance;
      this.ip = localStorage.getItem("ipadress") ?? "";
      if (!this.ip) {
        this.settingsOpen = true;
      } else {
        this.dataConnector2.connect(this.fullIP);
      }

      this.dataConnector2.subscribe(["ATBSpeedIndicator", "ETCSPantoCondition", "ETCSPantoVoltage", "SafetySystemType", "DeadManWarningLight", "ETCSDistanceTape", "SafetySystemSwapButton", "ERTMSebrakeMessage", "ETCSRedLightPassedLight", "ETCSshuntSRlight", "ETCSMenuBar", "SafetySystemAcceptButton", "DeadMansAlarm", "DeadManSystem"]);
  }

  public settingsChanged(params: { ip: string, useWakeLock: boolean }) {
    const { ip, useWakeLock } = params;
    this.useWakeLock = useWakeLock;
    setTimeout(() => this.setWakeLock().catch(e => console.error(e.message)), 100);
    if (ip !== this.ip || !this.dataConnector2.isConnected.value) {
      localStorage.setItem("ipadress", ip);
      this.ip = ip;
      this.dataConnector2.connect(this.fullIP);
    }

    this.settingsOpen = false;
  }

  public mainButtonPress(down: boolean): void {
    this.setTrainValue("ETCSMainButton", down ? 1 : 0, true);
  }

  public overridePress(down: boolean): void {
    this.setTrainValue("ETCSOverrideButton", down ? 1 : 0, true);
  }
  
  public option1Press(down: boolean): void {
    this.setTrainValue("ETCSOption1Button", down ? 1 : 0, true);
  }
  
  public option2Press(down: boolean): void {
    this.setTrainValue("ETCSOption2Button", down ? 1 : 0, true);
  }

  protected handleVisibilityChange = () => {
    if (this.wakelock !== null && document.visibilityState === 'visible') {
      this.requestWakeLock().then(result => {
        this.wakelock = result;
      }).catch(e => console.error(e.message));
    }
  }

  public get safetySystem() {
    return this.safetySystemType === 1 ? "ATB" : this.safetySystemType === 2 ? "ETCS" : "IS";
  }

  protected async setWakeLock(): Promise<void> {
    if (!this.useWakeLock) {
      this.releaseWakeLock();
      return;
    }

    const win = window as any;
    const nav = navigator as any;
    if (!('WakeLock' in win) || !('request' in win.WakeLock)) {
      return; // wakelock not supported
    }
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      document.addEventListener('visibilitychange', this.handleVisibilityChange);
      document.addEventListener('fullscreenchange', this.handleVisibilityChange);
      this.wakelock = await win.WakeLock.request("screen", { signal }) as AbortController;
    } catch (e: any) {
      console.error(e.message);
    }
  }

  protected async requestWakeLock(): Promise<AbortController | null> {
    const controller = new AbortController();
    const signal = controller.signal;
    try {
      (window as any).WakeLock.request('screen', { signal })
    } catch (e: any) {
      console.error(e.message);
    }


    return controller;
  }

  /**
   * release wakelock if set
   */
  protected releaseWakeLock(): void {

  }

  // public stopTransfer(): void {
  //   this.dataConnector.stopTransfer();
  //   this.dataRunning = false;
  //   document.getElementById("ws-but")?.classList.remove("wakelock");

  //   this.wakelock?.abort();
  //   document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  //   document.removeEventListener('fullscreenchange', this.handleVisibilityChange);
  //   this.wakelock = null;
  // }

  public setSafetySystem(id: number): void {
    this.setTrainValue('SafetySystemType', id, false);
    this.setTrainValue('SafetySystemSwapButton', 0);
  }

  public setTrainValue(key: string, value: number, whileDriving = true): void {
    const speed = 0;
    if (!whileDriving && Math.round(speed) > 0) return;
    this.dataConnector2.send({
        type: WebSocketTransferTypes.SetControlValue,
        data: {
            [key]: value
        }
      }
    );
  }
}
