
import { TsWebSocket2, WebSocketTransferTypes } from '@/data/websocket2';
import { Options, Vue } from 'vue-class-component'
@Options({
  computed: {
    dataChanged: { get() { return TsWebSocket2.Instance.values.ETCSDataChanged }},
    ebrakeMessage: { get() { return TsWebSocket2.Instance.values.ERTMSebrakeMessage }},
    emergencyBrake: { get() { return TsWebSocket2.Instance.values.EmergencyBrake }},
    trackFree: { get() { return TsWebSocket2.Instance.values.ETCSSpoorVrijLight }}
  }
})
export default class MFDMEssages extends Vue {
    dataChanged!: number;
    ebrakeMessage!: number;
    emergencyBrake!: number;
    trackFree!: number;
    
    public message1 = "Train data changed by external device";
    public message2 = "Noodrem";
    public messageFree = "Track ahead free?";
// public message3 = "Noodrem ATB";

    mounted(): void {
        TsWebSocket2.Instance.subscribe(["ETCSDataChanged", "ERTMSebrakeMessage", "EmergencyBrake", "ETCSSpoorVrijLight"]);       
    }

    public get message3() {
        console.log(this.ebrakeMessage);
        const id = this.ebrakeMessage;
        switch (id) {
            case 1: return "Noodrem dodeman";
            case 2: return "Noodrem ATB";
            default: return "Onbekende Noodremcode";
        }
    }

    public trackFreePress(): void {
        TsWebSocket2.Instance.send({
            type: WebSocketTransferTypes.SetControlValue,
            data: {
                ETCSSpoorVrijButton: 1
            }
        });
    }
}
