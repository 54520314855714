
import { TsWebSocket2 } from '@/data/websocket2';
import { Options, Vue } from 'vue-class-component'

@Options({
    props: {
        ip: String,
        dataRunning: Boolean,
        useWakeLock: Boolean
    },
    computed: {
        trainName: { get() { return TsWebSocket2.Instance.simInfo.locoName ?? "" }},
        simConnected: { get() { return TsWebSocket2.Instance.simInfo.connected ?? "" }}
    }
})
export default class MFDSettingsScreen extends Vue {
    // this property is passed from the app
    public ip!: string;

    public trainName!: string;
    public simConnected!: boolean;
    public dataRunning = false;
    public useWakeLock = true;
    public autoRequestWakeLock = true;

    // updated ip is stored here, as ip property cannot be modified
    protected updatedIp: string = "";

    public updateIP(e: Event) {
        if (e.target instanceof HTMLInputElement) {
            this.updatedIp = e.target.value;
        }
    }


    public toggleFullScreen(): void {
        const doc = document as any;
        const element = doc.body;
        var isFullscreen = doc.webkitIsFullScreen || doc.mozFullScreen || false;
        element.requestFullScreen = element.requestFullScreen || element.webkitRequestFullScreen || element.mozRequestFullScreen || function() {
            return false;
        };
        doc.cancelFullScreen = doc.cancelFullScreen || doc.webkitCancelFullScreen || doc.mozCancelFullScreen || function() {
            return false;
        };

        if (isFullscreen) {
            doc.cancelFullScreen() 
        } else {
            element.requestFullScreen();
        }
    }

    saveSettings(): void {
        // send back new ip through event
        this.$emit("settingsChanged", { ip: this.updatedIp, useWakeLock: this.useWakeLock });
    }

    public toggleWebsocket(): void {
        this.$emit("websocketStateChanged", { isRunning: this.dataRunning });
    }
}
